import { envBool } from '@nsf/utils/EnvUtils.js'

export const modules = [
  '@nsf/datadog',
  '@nsf/metrics',
  '@nsf/i18n',
  ...(!process.env.STORYBOOK_BUILD ? ['@nsf/csp'] : []),
  '@nsf/ui-library',
  '@nsf/base',
  '@nsf/elasticsearch',
  '@nsf/algolia',
  '@nsf/search',
  '@nsf/articles',
  '@nsf/article-preview',
  '@nsf/apotheka-web',
  '@nsf/pickup-select',
  '@nsf/pickup-select-v2',
  '@nsf/brands',
  '@nsf/sukl-widget',
  '@nsf/checkout',
  '@nsf/homepage',
  '@nsf/catalog',
  '@nsf/speed-curve',
  '@nsf/special-offers',
  '@nsf/faq',
  '@nsf/gtm',
  '@nsf/subscription',
  '@nsf/tags',
  '@nsf/delivery-fe',
  '@nsf/product-relationship-loader-persoo',
  '@nsf/product-relationship-loader-placement',
  '@nsf/cms',
  '@nsf/clubs',
  '@nsf/validations',
  '@nsf/local-pl-drogeria',
  '@nsf/features',
  '@nsf/web-chat-widget',
  '@nsf/my-account',
  '@nsf/convert',

  // health-check module should be the last one due to its startup probe which should check the whole app
  '@nsf/health-check',
]

// Removing the MyAccount modules only if is set BASE_LOGIN_ENABLED=false in .env)
if (envBool(process.env.BASE_LOGIN_ENABLED, true)) {
  modules.push(
    '@nsf/loyalty-page',
    '@nsf/my-account',
  )
}

export default { modules }
