export default {
  allow: [
    ...process.env.ROBOTS_ALLOW?.split('|') || [],
    '*?amp*',
    '*?amp%3Brefresh*',
    '*?fresh*',
    '*?menu=*',
    '*?p=*',
    '*?page*',
    '*?rating=*',
    '*?refresh*',
    '*?refreshing*',
    '*?ruleid*',
    '*?sku=*',
    '*&menu=*',
    '*&rating=*',
    '/_i/*',
    '/i18n/*',
    '*?m2=*',
  ],
  disallow: [
    ...process.env.ROBOTS_DISALLOW?.split('|') || [],
    '/*?*',
    '*/_e*',
    '*/cdn-cgi/l/email-protection',
    '*/graphql*',
    '*krotkie-daty',
    '*pdf',
    '/checkout/*',
    '/moje-konto/edycja-danych$',
    '/moje-konto/moje-zamowienia$',
    '/moje-konto/ulubione-produkty$',
    '/moje-konto$',
    '/my-account/powiadomienia-o-produktach$',
    '/search*',
    '/ulubione-produkty$',
  ],
}
