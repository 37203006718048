import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  cmsService: {
    tenant: 'PL',
    storeId: 2,
  },
  faq: {
    sendQuestionsToCallCenter: false,
  },
  advisory: {
    enabled: false,
  },
})
